import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import i18n from "../../lib/Language";

const ContactForm = ({}) => {
  return (
    <div className="contact_form">
      <h4>{i18n.t("contact.contactUs")}</h4>
      <ul className="part_contact">
        <h5>{i18n.t("contact.bussinessDepartment")}</h5>
        <li>
          <span>Email: </span>
          <a href="mailto:sale@novasquare.vn">sale@novasquare.vn</a>
        </li>
        <li>
          <span>Phone: </span>
          <a href="tel:+84971234042">(+84) 971.234.042</a>
        </li>
      </ul>
      <ul className="part_contact">
        <h5>{i18n.t("contact.careerDepartment")}</h5>
        <li>
          <span>Email: </span>
          <a href="mailto:careers@novasquare.vn">careers@novasquare.vn</a>
        </li>
        <li>
          <span>Phone: </span>
          <a href="tel:+84913111576">(+84) 913.111.576</a>
        </li>
      </ul>
      <ul className="part_contact">
        <h5>{i18n.t("contact.companyInfo")}</h5>
        <li>
          <span>{i18n.t("contact.companyName")}</span>
        </li>
        <li>
          <span>{i18n.t("contact.taxCode")}: 0401933243</span>
        </li>
        <li>
          <div style={{ display: "flex" }}>
            <span>{i18n.t("contact.address")}: &ensp;</span>
            <div>
              <span>{i18n.t("contact.addressCompany")}</span>
            </div>
          </div>
        </li>
        <li>
          <span>Email: </span>
          <a href="mailto:info@novasquare.vn">info@novasquare.vn</a>
        </li>
        <li>
          <span>Phone: </span>
          <a href="tel:+84913111576">(+84) 913.111.576</a>
        </li>
      </ul>
    </div>
  );
};

export default ContactForm;
